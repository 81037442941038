import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
            },
        }
    },

    validations: {
        form: {
            title: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            model:'catalog/model',
        }),
    },
    watch:{
        model(e){
            if(e){ 
                this.form.title = e.title;
            }
        }
    }, 
    methods: { 
        closePopup() {
            this.$emit('closePopup')
        },
        sendModel() {
            if(this.model){
                const payload = {
                    id: this.model.id,
                    data:this.form
                }
                this.$emit('changeModel', payload)
            }else{
                this.$emit('sendModel', this.form)
            }
        },
        ...mapMutations({
            changeModel: 'catalog/changeModel'
        }),
    },
    destroyed() {
        this.changeModel(null);
    }
}